import React from 'react';
import './UseCases.scss';

import { connect } from 'react-redux';

import { ScreenWithNavBarAndFooter } from 'app/components';
import { NewsSection } from "../Dashboard";

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const UseCases = connect(mapStateToProps, actionCreators)(props => {

    const { lang, locale_string } = props.pageInfo;
    
    return (
        <ScreenWithNavBarAndFooter className="page-use-cases">
            <section className="page-section page-header">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/use-cases/hero.jpg)' }}>
                </div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h1 className="sec-title">Use Cases</h1>
                        <p className="sec-description">
                            Lorem ipsum dolor sit amet consectetur. Morbi vel amet eget quam pellentesque eget elementum.
                        </p>
                    </div>
                </div>
            </section>
            <section className="page-section use-case-list-section">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="use-case-group use-case-locacafe">
                                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/use-cases/locacafe.png)' }}></div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <h3>Discover the Future of Coffee Enjoyment at LocaCafe!</h3>
                                        <p>Welcome to LocaCafe, where Coffee Meets Tech for an unmatched experience. Step into LocaCafe, where coffee and technology join forces for a one-of-a-kind adventure. Dive into the world of LocaMos tech, transforming your coffee outings. Discover the features that make LocaCafe the go-to spot for community connections and pure fun.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="use-case-group">
                                <h3>LocaMeet and LocaCall: Elevate Connections, Multiply Rewards</h3>
                                <div className="use-case-list">
                                    <div className="use-case-list-item">
                                        <div className="glowing-icon">
                                            <img src="/public_assets/images/element-icons/user-plus.svg" alt=""></img>
                                        </div>
                                        <p>Engage in activities such as making new friends, initiating and receiving calls at LocaCafe, and watch as you earn triple LocaBonus points.</p>
                                    </div>
                                    <div className="use-case-list-item">
                                        <div className="glowing-icon">
                                            <img src="/public_assets/images/element-icons/coffee.svg" alt=""></img>
                                        </div>
                                        <p>LocaCafe has become the heart of community connections, seamlessly bridging the gap between online and offline interactions, and inspiring everyone to unite.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="use-case-group">
                                <h3>LocaBiz: Save Smart, Enjoy More</h3>
                                <div className="use-case-list">
                                    <div className="use-case-list-item">
                                        <div className="glowing-icon">
                                            <img src="/public_assets/images/element-icons/group.svg" alt=""></img>
                                        </div>
                                        <p>Engage in activities such as making new friends, initiating and receiving calls at LocaCafe, and watch as you earn triple LocaBonus points.</p>
                                    </div>
                                    <div className="use-case-list-item">
                                        <div className="glowing-icon">
                                            <img src="/public_assets/images/element-icons/gift.svg" alt=""></img>
                                        </div>
                                        <p>Enhance your return visits with the allure of reclaiming a portion of your expenses. LocaCafe not only tantalizes your taste buds but also lets you save smart!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="use-case-group">
                                <h3>LocaMos: Merge Real and Virtual Realms</h3>
                                <div className="use-case-list">
                                    <div className="use-case-list-item">
                                        <div className="glowing-icon">
                                            <img src="/public_assets/images/element-icons/maps.svg" alt=""></img>
                                        </div>
                                        <p>LocaMos seamlessly merges the real and virtual worlds, providing a unique experience for customers using VR/AR devices at LocaCafe.</p>
                                    </div>
                                    <div className="use-case-list-item">
                                        <div className="glowing-icon">
                                            <img src="/public_assets/images/element-icons/arrow-up-and-down.svg" alt=""></img>
                                        </div>
                                        <p>Immerse yourself in an interactive space, encouraging customers to explore the perfect blend of reality and virtuality, making every visit an extraordinary adventure.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-section use-case-visit-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/use-cases/visit.jpg)' }}>
                </div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="sec-header">
                                <h1 className="sec-title">Visit LocaCafe Today!</h1>
                                <p className="sec-description">
                                    Experience the evolution of coffee culture, where LocaCafe is more than just a place to sip coffee; it's a destination for extraordinary connections and innovations. Join us and unlock a realm of unparalleled experiences. Your coffee adventure begins at LocaCafe!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <NewsSection />
        </ScreenWithNavBarAndFooter>
    )
})

export {
    UseCases
};