import React, { useState, useEffect, useRef } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { alertActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBarAndFooter, Slider } from 'app/components';

import { decodeHtmlContent } from "utils/AppHelper";


const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}


const Dashboard = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const partner_list = [
        '/public_assets/images/home/partners-section/hh-blockchain-vietnam.png',
        '/public_assets/images/home/partners-section/loca-mart.png',
        '/public_assets/images/home/partners-section/crypto-valley.png',
        '/public_assets/images/home/partners-section/loca-cafe.png'
    ]

    useEffect(() => {
        if (window.innerWidth > 767) {
            window.addEventListener('scroll', handleServiceScroll)
        }

        return () => {
            window.removeEventListener('scroll', handleServiceScroll)
        }
    }, [])
    
    function handleServiceScroll(e) {
        let serviceWrapper = document.getElementById('serviceWrapper');
        let fixedPos = serviceWrapper.getBoundingClientRect();
        let fixedHeader = document.getElementById('serviceHeader');
        let fixedHeaderPos = fixedHeader.getBoundingClientRect();

        let pos_end = (fixedPos.height - fixedHeaderPos.height - 64 - 200 - 64) * -1;

        // console.log('?????? scrollTop', scrollTop, fixedPos.top, pos_end);

        if (fixedPos.top < 200) {
            if (fixedPos.top < pos_end) {
                fixedHeader.style.position = 'relative';
                fixedHeader.style.top = 'unset';
                fixedHeader.style.left = 'unset';
                fixedHeader.style.width = 'unset';
                fixedHeader.parentElement.classList.remove('justify-content-between');
                fixedHeader.parentElement.classList.add('justify-content-end');
            } else {
                fixedHeader.style.position = 'fixed';
                fixedHeader.style.top = '200px';
                fixedHeader.style.left = fixedHeaderPos.x + 'px';
                fixedHeader.style.width = fixedHeaderPos.width + 'px';
                fixedHeader.parentElement.classList.remove('justify-content-between');
                fixedHeader.parentElement.classList.add('justify-content-end');
            }
        } else {
            fixedHeader.style.position = 'relative';
            fixedHeader.style.top = 'unset';
            fixedHeader.style.left = 'unset';
            fixedHeader.style.width = 'unset';
            fixedHeader.parentElement.classList.remove('justify-content-end');
            fixedHeader.parentElement.classList.add('justify-content-between');
        }
    }

    function renderPartnerItem(list) {
        if (!list || list.length === 0) return null;

        let partners = [];
        list.forEach((item, index) => {
            partners.push(
                <div className="col-6 col-md-3 mb-5 mb-md-0" key={'partner_item_' + index}>
                    <img className="img-fluid w-100" src={item}></img>
                </div>
            )
        })

        return (
            <div className="row">
                {partners}
            </div>
        )
    }

    return (
        <ScreenWithNavBarAndFooter className="page-home">
            <section className="page-section home-header">
                <div className="container-xl">
                    <div className="sec-content">
                        <div className="row align-items-center">
                            <div className="col-md-6 order-2 order-md-1">
                                <div className="sec-header">
                                    <h2 className="sec-title">Next-Gen Marketing Solutions For Businesses</h2>
                                    <div className="star-light-wrapper mb-4">
                                        <p className="sec-description">
                                            We are committed to transforming your business with our innovative marketing tools.
                                        </p>
                                    </div>
                                    <a href="#about" className="btn btn-lg btn-primary btn-200 w-50">Learn More</a>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2">
                                <img src="/public_assets/images/home/home-header/home-header-img.png" className="img-fluid w-100 mb-5 mb-md-0"></img>
                            </div>
                        </div>
                        <div className="row advantage-row">
                            <div className="col-md-4 advantage-col">
                                <div className="advantage-item mb-5 mb-md-0">
                                    <h3>
                                        <img src="/public_assets/images/home/home-header/coins-rotate-filled.png" width={32} height={32}></img> Revolutionize
                                    </h3>
                                    <p>We aspire to bring about a transformative change in the marketing field, challenging conventional approaches and fostering a dynamic and progressive industry landscape.</p>
                                </div>
                            </div>
                            <div className="col-md-4 advantage-col">
                                <div className="advantage-item mb-5 mb-md-0">
                                    <h3>
                                        <img src="/public_assets/images/home/home-header/lightbulb-02-filled.png" width={32} height={32}></img> Innovative Technology
                                    </h3>
                                    <p>We are committed to staying at the forefront of technological advancements, utilizing cutting-edge solutions to drive creativity and efficiency in marketing strategies.</p>
                                </div>
                            </div>
                            <div className="col-md-4 advantage-col">
                                <div className="advantage-item">
                                    <h3>
                                        <img src="/public_assets/images/home/home-header/coin-hand-filled.png" width={32} height={32}></img> Business Growth
                                    </h3>
                                    <p>Our mission includes actively contributing to the expansion and success of businesses, providing the tools and support necessary for sustained growth in a competitive market.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className="page-section about-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/about-section/globe.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-content">
                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="sec-header">
                                    <h2 className="sec-title">About LocaMos</h2>
                                    <div className="star-light-wrapper">
                                        <p className="sec-description">
                                            LocaMos is a technology company with a strong presence in Switzerland, Vietnam, Singapore, and the USA, specializing in Marketing Technology (MarTech). We lead the industry with pioneering solutions that seamlessly integrate Blockchain technology into marketing, providing businesses with cutting-edge marketing solutions. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row locamos-stats">
                            <div className="col-md-4 mb-4 mb-md-0">
                                <div className="locamos-stats-item-wrapper">
                                    <div className="locamos-stats-item">
                                        <h3>1 mililion</h3>
                                        <p>App Installations</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0">
                                <div className="locamos-stats-item-wrapper">
                                    <div className="locamos-stats-item">
                                        <h3>+5000</h3>
                                        <p>Business Partnerships</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0">
                                <div className="locamos-stats-item-wrapper">
                                    <div className="locamos-stats-item">
                                        <h3>+10</h3>
                                        <p>Global Presence</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="services" className="page-section services-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-6 pe-md-5">
                                <div className="d-flex flex-column justify-content-between h-100 pb-3">
                                    <div id="serviceHeader" className="sec-header">
                                        <h2 className="sec-title mb-4">Our Main Services</h2>
                                        <div className="star-light-wrapper">
                                            <p className="sec-description">
                                                LocaMos provides advanced marketing solutions for small and medium-sized businesses, seamlessly integrating Marketing Technology and Blockchain. We construct a comprehensive User Database by offering incentivizing rewards, similar to popular game models like Pokémon Go. Subsequently, we Connect Users to Businesses, enabling access to exclusive discount rewards and cashback, akin to traditional loyalty programs.
                                            </p>
                                        </div>
                                    </div>
                                    <p className="fst-italic">** To better understand our services and features, please refer to the content below that aligns with your needs.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div id="serviceWrapper" className="services-group-wrapper">
                                    <div className="service-group">
                                        <div className="service-group-item active">
                                            <img src="/public_assets/images/home/services-section/service-group-icon-1.png" width={160} height={160}></img>
                                            <h3>Discover, Connect, Earn - LocaMos Adventure!</h3>
                                            <p>Immerse in gifts and connect with friends through unforgettable experiences. Join a vibrant community, earn points, and get vouchers—inspired by the Pokemon Go game.</p>
                                            <hr className="my-4" />
                                            <Link to={NAVIGATION.FOR_USERS} className="btn btn-link btn-icon-right btn-services">
                                                Explore More
                                                <i className="fa-solid fa-circle-arrow-right"></i>
                                            </Link>
                                        </div>
                                        <div className="service-group-item">
                                            <img src="/public_assets/images/home/services-section/service-group-icon-2.png" width={160} height={160}></img>
                                            <h3>LocaMos - Revolutionizing Marketing Success!</h3>
                                            <p>Elevate your business with LocaMos. Our pay-after-results method ensures customer attraction and sales growth. Leading in Blockchain-integrated MarTech solutions, we redefine digital success.</p>
                                            <hr className="my-4" />
                                            <Link to={NAVIGATION.FOR_BUSINESSES} className="btn btn-link btn-icon-right btn-services">
                                                Explore More
                                                <i className="fa-solid fa-circle-arrow-right"></i>
                                            </Link>
                                        </div>
                                        <div className="service-group-item">
                                            <img src="/public_assets/images/home/services-section/service-group-icon-3.png" width={160} height={160}></img>
                                            <h3>Unlock Potential - Partner with LocaMos!</h3>
                                            <p>Join us in reshaping the future with LocaNFTs, LocaBonus, and the breakthrough LocaMos app. Discover limitless opportunities in our innovative marketing solutions.</p>
                                            <hr className="my-4" />
                                            <Link to={NAVIGATION.FOR_PARTNERS} className="btn btn-link btn-icon-right btn-services">
                                                Explore More
                                                <i className="fa-solid fa-circle-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="partners" className="page-section partners-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/partners-section/section-bg.png)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Our Ecosystem and Partners</h2>
                    </div>
                </div>
                <div className="partner-list">
                    <div className="container-xl">
                        { renderPartnerItem(partner_list) }
                    </div>
                </div>
            </section>
            <DownloadSection />
            <NewsSection />
        </ScreenWithNavBarAndFooter>
    )
})

const DownloadSection = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    return (
        <section id="download" className={`page-section download-section ${props.className !== '' ? props.className : ''}`}>
            <div className="container-xl">
                <div className="download-img-wrapper">
                    <div className="download-wrapper">
                        <div className="download">
                            <div className="col-md-5 pe-lg-3 pe-xl-5">
                                <div className="sec-header">
                                    <h2 className="sec-title">Download the LocaMos App now!</h2>
                                    <div className="star-light-wrapper mb-4">
                                        <p className="sec-description">Explore a New Life Full of Convenience and Unique Experiences!</p>
                                    </div>
                                    <img className="img-fluid mb-4 mb-md-0" src="/public_assets/images/home/download-section/stores.png"></img>
                                    <img className="img-fluid mobile" src="/public_assets/images/home/download-section/phone.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="download-img desktop" style={{ backgroundImage: 'url(/public_assets/images/home/download-section/phone.png)' }}></div>
                </div>
            </div>
        </section>
    )
})

const NewsSection = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const news = [
        {
            "title": "[Yahoo] LocaMos Global AG Officially Inaugurates New Headquarters in Zug, Switzerland, Bridging MarTech and Blockchain Innovation",
            "description": "LocaMos Global AG, an emerging name in the technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://finance.yahoo.com/news/locamos-global-ag-officially-inaugurates-034100325.html",
            "img_url": "https://s.yimg.com/ny/api/res/1.2/wCXsAxlezV.JLsLmkuKnmQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTY4MQ--/https://media.zenfs.com/en/newsfile_64/adb3aa3e19ddfa3700b678348d18581f",
        },
        {
            "title": "[Business Insider] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://markets.businessinsider.com/news/stocks/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain-1032786035",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
        {
            "title": "[Benzinga] LocaMos Global AG commits to applying technology to address real-life challenges. The company offers a range of tools that help attract and engage customers through high-experiential features to support physical businesses, creating smoother business operations and growth.",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.benzinga.com/pressreleases/23/11/35636290/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain",
            "img_url": "/public_assets/images/news/news-2.jpg",
        },
        {
            "title": "[News Max] LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland",
            "description": "LocaMos Global AG, a technology company based in Zug, Switzerland, recently established, is making waves by combining two crucial facets in the technology industry: MarTech (Marketing Technology) and Blockchain. MarTech emphasizes utilizing technology to optimize and enhance marketing campaigns, while Blockchain has the potential to transform many sectors through its security and transparency in transactions.",
            "url": "https://pr.newsmax.com/article/LocaMos-Global-AG-Pioneering-a-Revolutionary-Blend-of-MarTech-and-Blockchain?storyId=6549e967aabbe7000889a161&fbclid=IwAR2GzfuyxswWFZHM5aLdlhJKoWBRjTR4aCwjGiU1DqcqJf5NNIxUdbXUSOk_aem_AehULbt_gCLI7P0HxUPS64lhOmWMIdh46HdHz3cLs3a-NmaEkM0gYbla8wừycQujJh8",
            "img_url": "https://storage.googleapis.com/mmstudio-images/gallery/AhvOgFGA4jcj9Xtx9NZIpIwF4Vw2/89112505-1699329968-1.jpg",
        },
        {
            "title": "[TGAM] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.theglobeandmail.com/investing/markets/markets-news/MarketersMEDIA%20Newswire/21772309/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain/?fbclid=IwAR2gZL6arEordyYiLCe4KFjHIgOq2Tl6uJJjBauzNabt9qAy2barC64eymg_aem_Aehjv52c61JvXo8gw0HebSzgI5HKXPePgq0BPU-ueX52dFqE_r7yiH5vjJbDtxAdx7g",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
    ]

    return (
        <section id="news" className={`page-section news-section ${props.className !== '' ? props.className : ''}`}>
            <div className="container-xl">
                <div className="sec-header text-center">
                    <h2 className="sec-title">Our Latest News</h2>
                    <a href="#" className="btn btn-lg btn-primary btn-200 px-5">View all posts</a>
                </div>
                <div className="sec-content">
                    <Slider
                        className="news-slider"
                        list={news}
                        renderItem={(item, index) => (
                            <a href={item.url} target="_blank" className="article">
                                <div className="article-image">
                                    <img src={item.img_url} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="article-info">
                                    <h4 className="article-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                                    <p className="article-description" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.description) }}></p>
                                </div>
                            </a>
                        )}
                        overrideConfig={{
                            slidesPerView: 1,
                            breakpoints: {
                                768: {
                                    slidesPerView: 3
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    )
})

export {
    Dashboard,
    DownloadSection,
    NewsSection
}