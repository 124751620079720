import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import { NAVIGATION } from 'config';
import { pageInfo } from "app/redux/reducers/page_info.reducer";

const PrivateRoute = props => {
    const { auth, noInApp } = props;
    const { isLogin } = props.auth;
    const location = useLocation();

    if ( !isLogin ) {
        const unauthorized_destination = props.pageInfo.inapp === '1' ? NAVIGATION.AUTH_TOKEN : NAVIGATION.LOGIN
        return <Navigate to={unauthorized_destination} state={{ from: location.pathname }} replace={true} />;
    } if ( pageInfo.inapp === '1' && noInApp ) {
        return <Navigate to={NAVIGATION.MEMBERSHIP} state={{ from: location.pathname, page_title: 'Tem Rau' }} replace={true} />;
    } else {
        return props.children;
    }
};

const mapStateToProps = (state) => {
    const { auth, pageInfo } = state;
    return { auth, pageInfo };
}

export default connect(mapStateToProps, null)(PrivateRoute);