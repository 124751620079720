import { pageInfoConstants } from 'app/redux/constants';
import { pageInfoService } from 'services';

const fetch = (lang) => {
    return dispatch => {
        dispatch(request());

        pageInfoService.fetch_page_info(lang)
            .then(data => {
                dispatch(success(data));
            })
            .catch(error => {
                console.log(error);
                dispatch(failure());
            })
    }

    function request() { return { type: pageInfoConstants.FETCH } }
    function success(data) { return { type: pageInfoConstants.FETCH_SUCCESS, data } }
    function failure() { return { type: pageInfoConstants.FETCH_FAILURE } }
}

const change_lang = (target_lang) => {
    return { type: pageInfoConstants.CHANGE_LANGUAGE, data: target_lang }
}

const change_inapp = (target_mode) => {
    return { type: pageInfoConstants.CHANGE_INAPP, data: target_mode }
}

export const pageInfoActions = {
    fetch,
    change_lang,
    change_inapp
}