import { pageInfoConstants } from 'app/redux/constants';

const getCurrencyUnit = () => {
    switch (window.location.hostname) {
        default:
            return 'VNĐ';
    }
}

const getDefaultLang = () => {
    switch (window.location.hostname) {
        default:
            return 'en';
    }
}

const getDefaultLocaleString = () => {
    switch (window.location.hostname) {
        default:
            return 'en-US';
    }
}

const getLocaleString = (lang) => {
    switch (lang) {
        case 'en':
            return 'en-US';

        case 'vi':
            return 'vi-VN';

        default:
            return 'en-US';
    }
}

const initialState = {
    logo: '/assets/images/app-logo.png',
    brandname: 'LocaMos', 
    slogan: 'Bringing Real-Life Connections Back.',
    address: '',
    address_line2: '',
    phone_number: '', 
    email: 'info@locamos.com',
    domain: 'locamos.com',
    company_name: 'LocaMos Global AG',
    company_shortname: 'LocaMos',
    company_shortcode: 'LCM',
    company_registration_number: '',
    company_representative_person: '',
    company_representative_position: '',
    company_foundation_year: '2023',
    work_hours: "08:00 - 22:00",
    currency_unit: getCurrencyUnit(),
    display_lang: getDefaultLang(),
    locale_string: getDefaultLocaleString(),
    current_path: '/',
    inapp: 0,
}

export function pageInfo(state = initialState, action) {
    switch (action.type) {
        case pageInfoConstants.FETCH_SUCCESS:
            let fetch_time = new Date();
            return {
                ...state,
                ...action.data,
                timestamp: fetch_time.getTime()
            };

        case pageInfoConstants.CHANGE_LANGUAGE:
            return {
                ...state,
                display_lang: action.data,
                locale_string: getLocaleString(action.data)
            };

        case pageInfoConstants.CHANGE_INAPP:
            return {
                ...state,
                inapp: action.data,
            };

        case pageInfoConstants.FETCH:
        case pageInfoConstants.FETCH_FAILURE:
        default:
            return state;
    }
}