import React, { useEffect } from 'react';
import './AppAlert.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { alertActions } from 'app/redux/actions';

const AppAlert = (props) => {
    const { pageInfo, alert, clearAppAlert } = props;
    const lang = pageInfo.display_lang;

    useEffect(() => {
        if (alert.new_id) {

            setTimeout(() => {
                let $ = require('jquery');

                $('#toast'+alert.new_id).removeClass('show-toast');
            }, 4700);

            setTimeout(() => {
                clearAppAlert(alert.new_id);
            }, 5000);
            
        }
    }, [alert.new_id, clearAppAlert])

    const renderAlerts = (list) => {
        if (!list || list.length === 0) return null;

        let alert_list = [];
        list.map((item, index) => {
            alert_list.push(
                <div id={`toast${item.id}`} className={`toast show show-toast toast-${item.type}`} role="alert" aria-live="assertive" aria-atomic="true" key={index}>
                    <div className="toast-header">
                        <i className={`fad fa-${item.icon} text-${item.type} me-2`}></i>
                        <span className="font-weight-semibold me-auto">{text.app_alert.notice[lang]}</span>
                        {/* <small>11 mins ago</small> */}
                        <button 
                            type="button" className="btn-close" 
                            onClick={(e) => {
                                e.preventDefault();
                                clearAppAlert(item.id) 
                            }}
                        ></button>
                    </div>
                    <div className="toast-body">
                        { item.message }
                    </div>
                    <div className="toast-timer">
                        <span className="toast-timer-progressbar"></span>
                    </div>
                </div>
            );

            return item;
        });

        return alert_list;
    }

    return (
        <div className={`app-alert-wrapper ${ alert.list && alert.list.length > 0 ? 'show' : '' }`}>
            { renderAlerts(alert.list) }
        </div>
    );
}

const mapStateToProps = (state) => {
    const { alert, pageInfo } = state;
    return { alert, pageInfo };
}

const actionCreators = {
    clearAppAlert: alertActions.clear
}

export default connect(mapStateToProps, actionCreators)(AppAlert);