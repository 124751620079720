export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    INFO: 'ALERT_INFO',
    WARNING: 'ALERT_WARNING',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const authConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    USERS_UPDATE_INFO: 'USERS_UPDATE_INFO',
    USERS_UPDATE_TOKEN: 'USERS_UPDATE_TOKEN'
};

export const pageInfoConstants = {
    FETCH: 'PAGE_INFO_FETCHING',
    FETCH_SUCCESS: 'PAGE_INFO_FETCH_SUCCESS',
    FETCH_FAILURE: 'PAGE_INFO_FETCH_FAILURE',
    CHANGE_LANGUAGE: 'PAGE_INFO_CHANGE_LANGUAGE',
    CHANGE_INAPP: 'PAGE_INFO_CHANGE_INAPP',
}

export const orderConstants = {
    UPDATE: 'ORDER_UPDATE',
    CLEAR: 'ORDER_CLEAR'
}