import React from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, user } = props.auth;

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top shadow top-navbar">
            <div className="container-xl">
                <button className="navbar-toggler ms-3 order-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-1" to={NAVIGATION.DASHBOARD}>
                    <img src="/public_assets/images/header-logo.svg" alt="" height={40}></img>
                </Link>
                <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto pt-3 pt-md-0">
                        <li className="nav-item">
                            <NavLink to={NAVIGATION.DASHBOARD} className="nav-link">{text.top_nav_bar.home[lang]}</NavLink>
                        </li>
                        <li className="nav-item">
                            {/* <NavLink to={NAVIGATION.ABOUT_US} className="nav-link">{text.top_nav_bar.about_us[lang]}</NavLink> */}
                            <a href={`${window.location.origin}#about`} className="nav-link">{text.top_nav_bar.about_us[lang]}</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">{text.top_nav_bar.services[lang]}</a>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link to={NAVIGATION.FOR_USERS} className="dropdown-item">{text.top_nav_bar.services.for_users[lang]}</Link>
                                </li>
                                <li>
                                    <Link to={NAVIGATION.FOR_BUSINESSES} className="dropdown-item">{text.top_nav_bar.services.for_businesses[lang]}</Link>
                                </li>
                                <li>
                                    <Link to={NAVIGATION.FOR_PARTNERS} className="dropdown-item">{text.top_nav_bar.services.for_partners[lang]}</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink to={NAVIGATION.USE_CASES} className="nav-link">{text.top_nav_bar.use_cases[lang]}</NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink to={NAVIGATION.DASHBOARD} className="nav-link">{text.top_nav_bar.resources[lang]}</NavLink>
                        </li> */}
                    </ul>
                </div>
                <ul className="navbar-nav ms-auto ms-lg-4 order-2 order-lg-4">
                    <li className="nav-item">
                        <Link to={NAVIGATION.CONTACT_US} className="btn btn-outline-light">{text.top_nav_bar.contact_us[lang]}</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)((props) => {
	const { pageInfo } = props;
	const lang = pageInfo.display_lang;

	return (
		<footer className="site-footer">
			<div className="container">
				<div className="row align-items-end">
					<div className="col-md-8">
						<img
							src="/public_assets/images/logo-white.svg"
							height={72}
							width={72}
							alt=""
							className="footer-brand"
						/>
                        <p className="h5 fw-semibold mb-4">Join us to discover the <br className="desktop" />LocaMos MarTech today!</p>
						<div className="subscribe-form mb-5 mb-md-0">
							<form>
								<div className="input-group">
									<input className="form-control" type="email" placeholder="Subscribe to our Newsletter"></input>
									<button className="btn btn-primary btn-submit px-4" type="submit">
										Send
									</button>
								</div>
							</form>
						</div>
					</div>
					<div className="col-6 col-md-2">
						<h5>{text.footer.home[lang]}</h5>
						<ul className="footer-links">
							<li>
								<Link to={NAVIGATION.ABOUT_US}>{text.footer.home.about_us[lang]}</Link>
							</li>
							<li>
								<Link to={NAVIGATION.SERVICES}>{text.footer.home.services[lang]}</Link>
							</li>
							<li>
								<Link to={NAVIGATION.USE_CASES}>{text.footer.home.use_cases[lang]}</Link>
							</li>
						</ul>
					</div>
					<div className="col-6 col-md-2">
						<h5>{text.footer.resources[lang]}</h5>
						<ul className="footer-links">
							<li>
								<Link to={NAVIGATION.WHITEPAPER}>{text.footer.resources.white_paper[lang]}</Link>
							</li>
							<li>
								<Link to={NAVIGATION.BLOGS}>{text.footer.resources.blogs[lang]}</Link>
							</li>
							<li>
								<Link to={NAVIGATION.FAQS}>{text.footer.resources.faqs[lang]}</Link>
							</li>
						</ul>
					</div>
				</div>
                <div className="footer-social flex-column flex-md-row">
                    <div className="language-bar">
                        <button className="btn btn-link p-0">
                            <i className="fa-regular fa-globe"></i>
                            English (US)
                        </button>
                    </div>
                    <div className="footer-social-links">
                        <a href="https://www.facebook.com/LocaMosGlobalAg" target="_blank"><i className="fa-brands fa-fw fa-square-facebook"></i></a>
                        <a href="https://twitter.com/@LocaMosGlobalAg" target="_blank"><i className="fa-brands fa-fw fa-x-twitter"></i></a>
                        {/* <a href="https://www.instagram.com/" target="_blank"><i className="fa-brands fa-fw fa-instagram"></i></a> */}
                        <a href="https://www.linkedin.com/company/joinlocamos/" target="_blank"><i className="fa-brands fa-fw fa-linkedin"></i></a>
                        <a href="https://www.youtube.com/@joinlocamos" target="_blank"><i className="fa-brands fa-fw fa-youtube"></i></a>
                    </div>
                </div>
				<div id="copyright" className="site-copyright">
					<div className="row">
						<div className="col-md-6 text-center text-md-start mb-3 mb-md-0 fw-semibold">
							&copy; 2023 LocaMos Global AG.
							All rights reserved.
						</div>
						<div className="col-md-6 text-center text-md-end">
							<Link to={NAVIGATION.PRIVACY_POLICY} className="me-4">{text.footer.links.privacy_policy[lang]}</Link>
							<Link to={NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
});

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}