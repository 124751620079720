const he = require('he');

function getUri(segment) {
    if (segment > 0 && typeof segment === 'number') {
        const pathName = window.location.pathname;
        const uri = pathName.split('/');
        // console.log(uri);
        return uri[segment] ? uri[segment] : '';
    }

    return false;
}

function getParam(name) {
    if (name) {
        const queryString = window.location.search;
        const param = getParameterByName(name, queryString);
        return param ? param : '';
    }

    return false;
}

function getParamPath() { //get full param
    const pathName = window.location.href.split('?');
    if ((pathName.length>1)&&(pathName[1])) {
        const listParam = pathName[pathName.length - 1].split('&');
        let lastUri = ""
        for (let i = 0; i < listParam.length; i++) {
            const mparam = listParam[i].split('=')[1]
            if (mparam) {
                if (lastUri.length > 0){
                    lastUri = lastUri+"&"
                }
                lastUri = lastUri+listParam[i]                    
            } 
            
        }       
        if (lastUri){
            return JSON.parse('{"' + decodeURI(lastUri).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')            
        }
    }
    return null;
}

function getParamsFromUrl(url) { //get full param
    const pathName = url.split('?');
    if ((pathName.length>1)&&(pathName[1])) {
        const listParam = pathName[pathName.length - 1].split('&');
        let lastUri = ""
        for (let i = 0; i < listParam.length; i++) {
            const mparam = listParam[i].split('=')[1]
            if (mparam) {
                if (lastUri.length > 0){
                    lastUri = lastUri+"&"
                }
                lastUri = lastUri+listParam[i]                    
            } 
            
        }       
        if (lastUri){
            return JSON.parse('{"' + decodeURI(lastUri).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')            
        }
    }
    return null;
}

//Bỏ các ký tự đặc biệt
function formatString(string) {
    let str = string.replace(/[^0-9a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ?!,.-_$():'"*²%<>+=-\s]/gi, '');
    return str
}

function getID() {
    const pathName = window.location.pathname;
    if (pathName) {
        const uri = pathName.split('/');
        const lastUri = uri[uri.length - 1].split('-');
        const lastPartOfSlug = lastUri[lastUri.length - 1];

        const id = lastPartOfSlug.replace(/\D/g, '');
        return parseInt(id) ? parseInt(id) : '';
    }

    return '';
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function createSlugLink(str) {
    // readmore: https://freetuts.net/tao-slug-tu-dong-bang-javascript-va-php-199.html
    var slug;

    //Đổi chữ hoa thành chữ thường
    slug = str.toLowerCase();

    // Bổ sung - Xóa các ký tự đặc biệt, symbol, icon, ..
    slug = slug.replace(/[^\p{L}\p{N}\-\s]/giu, '');
 
    //Đổi ký tự có dấu thành không dấu
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');
    //Xóa các ký tự đặt biệt
    slug = slug.replace(/²/gi, '2');
    slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, "-");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/\-\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-/gi, '-');
    slug = slug.replace(/\-\-/gi, '-');
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = '@' + slug + '@';
    slug = slug.replace(/\@\-|\-\@|\@/gi, '');

    return slug;
}
function formatPrice(price) {
    return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function checkUrlContains(url, containString)
{
    return url.indexOf(containString) > -1;
}

function decodeHtmlContent(content) {
    if (!content) return null;
    content = content.replace(/\\\'_/gi, "'");
    content = content.replace(/\\\"_/gi, '"');
    content = content.replace(/\\'|\'_/gi, "&apos;");
    content = content.replace(/\\"|\"_/gi, "&quot;");
    content = he.unescape(he.decode(content));
    content = content.replace(/\r\n\r\n/gi, '<br />');
    content = content.replace(/\r\n/gi, '<br />');
    content = content.replace(/\n/gi, '<br />');
    content = content.replace(/\r/gi, '<br />');
    
    return content;
}

function encodeHTMLContent(content) {
    if (!content) return null;
    return he.encode(he.escape(content));
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export { getUri, getParam, getID, createSlugLink, getParamPath, formatPrice, checkUrlContains, formatString, decodeHtmlContent, encodeHTMLContent, uuidv4, getParamsFromUrl }
