import { BASE_API, BASE_API_LOCAMOS } from 'config'

const API = {
    // Authentication
    'LOGIN': BASE_API_LOCAMOS + '/auth/phone-number',
    'LOGIN_SOCIAL': BASE_API_LOCAMOS + '/auth/social-network',
    'REGISTER': BASE_API + '/user/register-membership',
    'FETCH_REFERER_INFO': BASE_API_LOCAMOS + '/users/get-user-by-link',
    'FETCH_USER_INFO': BASE_API + '/user/membership-info',

    // Homepage
    'FETCH_PAGE_INFO': BASE_API + '/website-info',

    // Register Form
    'REGISTER_MEMBERSHIP': BASE_API + '/register-membership',
    'FETCH_NEARBY_STORE': BASE_API + '/user/locamart-stores',
    'CHECK_USER_REFFERER': BASE_API + '/user/referrer-info',
    'ACTIVE_MEMBERSHIP': BASE_API + '/user/active-code-card',
    'REDEEM_MEMBERSHIP': BASE_API + '/user/redeem-membership',

    // Buy Membership
    'CREATE_ORDER': BASE_API + '/user/create-transaction',
    'UPLOAD_IMAGE': BASE_API + '/user/upload-image',
    'FETCH_ORDER_VNPAY_INFO': BASE_API + '/user/check-transaction-vnpay',

    // Account
    'FETCH_USER_REDEEM_HISTORY': BASE_API + '/user/user-membership-history-redeems',
    'FETCH_USER_COMMISSIONS': BASE_API + '/user/list-commissions',
    'UPDATE_USER_INFO': BASE_API + '/user/update-info',
    'FETCH_USER_REFFERALS': BASE_API + '/user/list-referrer',
    'FETCH_REFERRAL_REVENUE': BASE_API + '/user/revenue-detail/'
}

export default API;