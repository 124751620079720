import { API, Request } from 'network';
import { uuidv4 } from 'utils/AppHelper';
import axios from 'axios';

const login = (username, password) => {
    const request = new Request();

    let data = JSON.stringify({
        phone_number: username,
        password
    })

    return request.post_locamos(API.LOGIN, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(result => {
            // console.log('?????? auth login', result);
            // fetch_user_info(result.access_token);
            
            try {
                if (result.status === 201) {
                    return result.data;
                } else {
                    console.log('API ' + API.LOGIN + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return 'Tài khoản hoặc mật khẩu không đúng';
                }
            } catch (error) {
                console.log('API ' + API.LOGIN + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

const login_social = (social_token, social_type) => {
    const request = new Request();

    let data = JSON.stringify({
        social_token,
        social_type
    })

    return request.post_locamos(API.LOGIN_SOCIAL, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(result => {
            // console.log('?????? auth login social', result);
            
            try {
                if (result.status === 201) {
                    return result.data;
                } else {
                    console.log('API ' + API.LOGIN_SOCIAL + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return result.content;
                }
            } catch (error) {
                console.log('API ' + API.LOGIN_SOCIAL + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

const register = (form_data) => {
    const request = new Request();

    let data = JSON.stringify(form_data)

    return request.post(API.REGISTER, data)
        .then(result => {
            try {
                if (result.status === 200) {
                    return result.status;
                } else {
                    console.log('API ' + API.REGISTER + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return result.content;
                }
            } catch (error) {
                console.log('API ' + API.REGISTER + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

const fetch_user_info = (token) => {
    const request = new Request();

    const data = JSON.stringify({
        token
    })

    return request.post(API.FETCH_USER_INFO, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(result => {
            // return fake data
            // return {
            //     "userApp": {
            //         "id": 6776,
            //         "username": "thaithuy9201@gmail.com",
            //         "full_name": "NGUYỄN THỊ THÁI THUỶ",
            //         "avatar": null,
            //         "banner": null,
            //         "email": "thaithuy9201@gmail.com",
            //         "phone": "84987654321",
            //         "birthday": "2023-08-04T17:00:00.000Z",
            //         "created_at": "2023-08-14T10:58:56.472Z",
            //         "is_membership": false,
            //         "code": "5auwnMi2bQra46aghPtfSyGSL2r1EQ",
            //         "wallet": {
            //             "usd": "99000",
            //             "nft": {
            //                 "data": [
            //                     {
            //                         "id": 9149,
            //                         "name": "Khu vực 105 Đ. Bắc Yên Lũng, An Phú, Hoài Đức, Hà Nội, Việt Nam",
            //                         "handle": "khu-vuc-105-d-bac-yen-lung-an-phu-hoai-duc-ha-noi-viet-nam",
            //                         "map_captured": "https://storage.googleapis.com/locamos-web-storage/21.009412026328818-105.71281492710115.png",
            //                         "long": 105.71281492710115,
            //                         "lat": 21.009412026328818,
            //                         "block_radius": 50,
            //                         "country": "VN",
            //                         "province": "HANOI",
            //                         "district": "HOAIDUC"
            //                     }
            //                 ],
            //                 "meta": {
            //                     "total_records": 1
            //                 }
            //             },
            //             "loca_bonus": "989365.3623188406",
            //             "loca": "0",
            //             "version": 7
            //         }
            //     },
            //     "membership": {
            //         "refererFullname": "HungGGGGG",
            //         "address": "Số 5 ngõ 255/84 đường Lĩnh Nam, Phường Vĩnh Hưng",
            //         "created_at": "15:37:38 14-11-2023",
            //         "refererUsername": "hungnucha123@gmail.com",
            //         "userId": 6776,
            //         "provinceId": "01",
            //         "full_name": "Nguyễn, Thị : mỹ - Du/ng 09",
            //         "districtId": "002",
            //         "refererId": 4100,
            //         "id": "655331d211eca668a8b2a62e",
            //         "username": "thaithuy9201@gmail.com",
            //         "registerDate": "15:37:38 14-11-2023",
            //         "status": false
            //     },
            //     "membership_status" : "ACTIVE"
            // }
            // return {
            //     "membership_status_label": "Chưa kích hoạt",
            //     "membership": {
            //         "store_id": null,
            //         "refererFullname": null,
            //         "address": null,
            //         "is_active": false,
            //         "created_at": "11:24:30 23-11-2023",
            //         "active_date": null,
            //         "day_delivery": null,
            //         "refererUsername": null,
            //         "membership_from_date": null,
            //         "userId": 195,
            //         "provinceId": null,
            //         "full_name": null,
            //         "districtId": null,
            //         "refererId": null,
            //         "phone": null,
            //         "membership_to_date": null,
            //         "id": "655ed3fee074bf400f0d9703",
            //         "username": "84387729035",
            //         "registerDate": null
            //     },
            //     "userApp": {
            //         "social_id": null,
            //         "full_name": "Thư id 195",
            //         "code": null,
            //         "user_id": 195,
            //         "phone": "84387729035",
            //         "dvoucher_quantity": 0,
            //         "email": "tnagamingsw@gmail.com",
            //         "username": "84387729035"
            //     },
            //     "membership_status": "ACTIVE"
            // }
            // end return fake data
            // console.log(result);
            try {
                if (result.status === 200) {
                    return result.data;
                } else {
                    console.log('API ' + API.FETCH_USER_INFO + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return result.content;
                }
            } catch (error) {
                console.log('API ' + API.FETCH_USER_INFO + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

const fetch_referer_info = (ref_id) => {
    const request = new Request();

    return request.get(API.FETCH_REFERER_INFO + '/' + ref_id)
        .then(result => {
            try {
                if (result.status && result.status === 500) {
                    console.log('API ' + API.FETCH_REFERER_INFO + ' lỗi status: ' + result.statusCode + ', nội dung: ' + result.message)
                    return null;
                } else {
                    return result
                }
            } catch (error) {
                console.log('API ' + API.FETCH_REFERER_INFO + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

export const authService = {
    login,
    login_social,
    register,
    fetch_referer_info,
    fetch_user_info
}