import { API, Request } from 'network';


const getLocaleString = (lang) => {
    switch (lang) {
        case 'en':
            return 'en-US';

        default:
            return 'vi-VN';
    }
}

const fetch_page_info = (lang) => {
    let params = { lang }

    const request = new Request();
    return request.get(API.FETCH_PAGE_INFO, params)
        .then(result => {
            try {
                if (result.status === 200) {
                    return {
                        ...result.data,
                        display_lang: lang,
                        locale_string: getLocaleString(lang)
                    };
                } else {
                    console.log('API ' + API.FETCH_PAGE_INFO + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                    return null;
                }
            } catch (error) {
                console.log('API ' + API.FETCH_PAGE_INFO + ' lỗi trycatch: ' + error);
                return null;
            }
        });
}

export const pageInfoService = {
    fetch_page_info,
}