import { alertConstants } from 'app/redux/constants';

const initialState = {
	new_id: null,
	list: []
}

export function alert(state = initialState, action) {
	switch (action.type) {
		case alertConstants.SUCCESS:
			return {
				new_id: state.list.length + 1,
				list: [
					{
						id: state.list.length + 1,
						type: 'success',
						icon: 'check-circle',
						message: action.message
					},
					...state.list
				]
			}

		case alertConstants.ERROR:
			return {
				new_id: state.list.length + 1,
				list: [
					{
						id: state.list.length + 1,
						type: 'danger',
						icon: 'times-circle',
						message: action.message
					},
					...state.list
				]
			}

		case alertConstants.INFO:
			return {
				new_id: state.list.length + 1,
				list: [
					{
						id: state.list.length + 1,
						type: 'info',
						icon: 'info-circle',
						message: action.message
					},
					...state.list
				]
			}

		case alertConstants.WARNING:
			return {
				new_id: state.list.length + 1,
				list: [
					{
						id: state.list.length + 1,
						type: 'warning',
						icon: 'info-circle',
						message: action.message
					},
					...state.list
				]
			}

		case alertConstants.CLEAR:
			return {
				new_id: null,
				list: state.list.filter((item) => item.id !== action.id)
			};
			
		default:
			return state
	}
}