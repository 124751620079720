import axios from 'axios'

export default class Request {

    // constructor() {
    //     // config defaults
    //     axios.defaults.withCredentials = true;
    // }

    cancel = () => {
        if (this.source) {
            this.source.cancel('')
        }
    }

    get = async (url, params = {}, configs = {}) => {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        let config = {
            cancelToken: this.source.token,
            ...configs
        };
        if (params) {
            config['params'] = params
        }

        return await axios.get(url, config)
            .then(function (response) {
                // handle success
                // console.log(response);
                return response.data;
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    console.log('canceled', thrown.message);
                } else {
                    // handle error
                    console.log(thrown);
                    return {
                        'status': 500,
                        'content': 'Kết nối đến máy chủ thất bại',
                        'data': {}
                    };
                }
            })
            .finally(function () {
                // always executed
            });
    };

    post = async (url, data, configs = {}) => {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        let config = {
            cancelToken: this.source.token,
            ...configs
        };

        return await axios.post(url, data, config)
            .then(function (response) {
                // handle success
                // console.log(response);
                return response.data;
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    console.log('canceled', thrown.message);
                } else {
                    // handle error
                    console.log(thrown);
                    return {
                        'status': 500,
                        'content': 'Kết nối đến máy chủ thất bại',
                        'data': {}
                    };
                }
            })
            .finally(function () {
                // always executed
            });
    }

    post_locamos = async (url, data, configs = {}) => {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        let config = {
            cancelToken: this.source.token,
            ...configs
        };

        return await axios.post(url, data, config)
            .then(function (response) {
                // handle success
                // console.log(response);
                return {
                    status: response.status,
                    data: response.data,
                    content: 'Success'
                };
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    console.log('canceled', thrown.message);
                } else {
                    // handle error
                    console.log(thrown);
                    if (thrown.response) {
                        return {
                            'status': thrown.response.data.statusCode,
                            'content': thrown.response.data.message,
                            'data': {}
                        };
                    } else {
                        return {
                            'status': 500,
                            'content': 'Kết nối đến máy chủ thất bại',
                            'data': {}
                        };
                    }
                }
            })
            .finally(function () {
                // always executed
            });
    }
}
