import React from 'react';
import './Services.scss';

import { connect } from 'react-redux';

import { ScreenWithNavBarAndFooter } from 'app/components';
import { DownloadSection } from "../Dashboard";

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const ForUsers = connect(mapStateToProps, actionCreators)(props => {
    const { lang, locale_string } = props.pageInfo;

    return (
        <ScreenWithNavBarAndFooter className="services-page">
			<div className="page-bg" style={{ backgroundImage: 'url(/public_assets/images/services/services-bg.svg)' }}></div>
            <section className="page-section page-header services-header">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <button className="btn btn-lg btn-outline-light btn-rounded px-5 mb-5">
							<i className="fa-regular fa-user fa-lg me-3"></i>For Users
                        </button>
                        <h2 className="sec-title">Discover the World of LocaMos</h2>
                        <p className="sec-description">Supporting: LocaMos is more than just an app. It&apos;s a gateway to a vibrant community, seamless connections, and limitless opportunities to earn points, vouchers, and more. Embrace a holistic way of living and immerse yourself in a revolutionary marketing experience inspired by the dynamics of the Pokemon Go game.</p>
                    </div>
                </div>
            </section>
            <section className="page-section">
				<div className="container-xl">
					<div className="sec-content">
						<img src="/public_assets/images/services/for-users/explore-locamos.jpg" className="img-fluid w-100 rounded-4" alt="" />
						<p className="image-description"><b>LocaMos App</b> - An all-in-one platform that helps you manage your contacts and maintain your relationships. Enjoy the benefits of this super app!</p>
					</div>
				</div>
            </section>
            <section className="page-section">
				<div className="container-xl">
					<div className="sec-content">
						<div className="row justify-content-around">
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/group.svg" alt=""></img>
								</div>
								<h5 className="h4">01</h5>
								<p className="mb-0">
									Make new friends - earn LocaBonus points with <b>LocaMeet</b> feature.
								</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/group.svg" alt=""></img>
								</div>
								<h5 className="h4">02</h5>
								<p className="mb-0">
									Stay connected with others by making calls and receiving LocaBonus points
									with <b>LocaCall</b> feature.
								</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/gift.svg" alt=""></img>
								</div>
								<h5 className="h4">03</h5>
								<p className="mb-0">
									Save consumer expenses with Cashback using <b>LocaBonus</b> points through
									the LocaBiz feature.
								</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/voucher.svg" alt=""></img>
								</div>
								<h5 className="h4">04</h5>
								<p className="mb-0">
									Convert LocaBonus points into <b>D-LocaVouchers</b> for dining, shopping,
									and using services within our global network of affiliated businesses.
								</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/arrow-trend-up.svg" alt=""></img>
								</div>
								<h5 className="h4">05</h5>
								<p className="mb-0">
									Hunt for vouchers, gifts, and valuable items in an entertaining experience
									reminiscent of games like Pokemon Go.
								</p>
							</div>
						</div>
					</div>
				</div>
            </section>
			<DownloadSection />
            <section className="page-section">
				<div className="container-xl">
					<div className="sec-header text-center">
						<h2 className="sec-title">Download the LocaMos App now!</h2>
						<p className="sec-description">Follow Our Guide to Begin the Endless Journey of<br className="desktop" /> Hunting Items and Gifts</p>
						<a className="btn btn-lg btn-primary btn-icon-right px-5 mt-4" target="_blank" href="https://download.locamos.com">
							Download Now<i className="fa-regular fa-sharp fa-arrow-down-to-line"></i>
						</a>
					</div>
					<div className="sec-content">
						<div className="row justify-content-center">
							<div className="col-md-8">
								<div className="service-step-item">
									<div className="title-with-line">
										01
										<div className="w-100"></div>
									</div>
									<p className="description">
										Scan the QR code, register, and receive OTP on your phone.
									</p>
								</div>
								<div className="service-step-item">
									<div className="title-with-line">
										02
										<div className="w-100"></div>
									</div>
									<p className="description">
										Choose "Explore Around" and locate the yellow gift box icon.
									</p>
								</div>
								<div className="service-step-item">
									<div className="title-with-line">
										03
										<div className="w-100"></div>
									</div>
									<p className="description">
										Click "Items Hunting," use the AR Camera to find items.
									</p>
								</div>
								<div className="service-step-item">
									<div className="title-with-line">
										04
										<div className="w-100"></div>
									</div>
									<p className="description">
										Check in with a required selfie for item collection.
									</p>
								</div>
								<div className="service-step-item">
									<div className="title-with-line">
										05
										<div className="w-100"></div>
									</div>
									<p className="description">
										Follow instructions to use or exchange items.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ForBusinesses = connect(mapStateToProps, actionCreators)(props => {
    const { lang, locale_string } = props.pageInfo;

    return (
        <ScreenWithNavBarAndFooter className="services-page">
			<div className="page-bg" style={{ backgroundImage: 'url(/public_assets/images/services/services-bg.svg)' }}></div>
            <section className="page-section page-header services-header">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <button className="btn btn-lg btn-outline-light btn-rounded px-5 mb-5">
                            <i className="fa-regular fa-user-group fa-lg me-3"></i>For Businesses
                        </button>
                        <h2 className="sec-title">Revolutionizing Your Marketing Strategy</h2>
                        <p className="sec-description">LocaMos presents a groundbreaking marketing solution, revolutionizing your approach and offering an unparalleled edge to your marketing strategy.</p>
                    </div>
                </div>
            </section>
            <section className="page-section">
				<div className="container-xl">
					<div className="sec-content">
						<img src="/public_assets/images/services/for-businesses/challenge-in-marketing.jpg" className="img-fluid w-100 rounded-4 mb-5" alt="" />
						<div className="sec-header text-center">
							<h2 className="sec-title">The Challenge in Marketing Today</h2>
							<p className="sec-description">Ever wondered why your online ads aren't bringing in the expected results? Tired of paying upfront without knowing if it's worth it? Small and Medium businesses (SMEs) often struggle with online advertising, unsure if the investment will lead to actual results. The upfront costs of traditional marketing can be intimidating, especially when there's uncertainty about its effectiveness.</p>
						</div>
					</div>
				</div>
            </section>
            <section className="page-section">
				<div className="container-xl">
					<div className="sec-content">
						<div className="row justify-content-around">
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="sec-header">
									<h2 className="sec-title">Our Outstanding Marketing Solutions</h2>
								</div>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/heart.svg" alt=""></img>
								</div>
								<h5 className="h4">Cost Savings</h5>
								<p className="mb-0">Pay a low fee only when customers use your product or service. No upfront costs.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/ruler.svg" alt=""></img>
								</div>
								<h5 className="h4">Clear Measurement</h5>
								<p className="mb-0">Precisely measure advertising conversion and real store visits.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/gift.svg" alt=""></img>
								</div>
								<h5 className="h4">Building Relationships</h5>
								<p className="mb-0">Foster customer connections through LocaBiz for effective care campaigns.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/arrow-up-and-down.svg" alt=""></img>
								</div>
								<h5 className="h4">Effective Advertising</h5>
								<p className="mb-0">Target ads with LocaAds to reach the right audience by location.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/badge-check.svg" alt=""></img>
								</div>
								<h5 className="h4">Unique Experiences</h5>
								<p className="mb-0">Enjoy diverse interactive experiences such as hunting for items, reward points, and vouchers....inspired by the game Pokemon Go.</p>
							</div>
						</div>
					</div>
				</div>
            </section>
            <section className="page-section">
				<div className="container-xl">
					<div className="sec-content">
						<div className="row justify-content-around">
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="sec-header">
									<h2 className="sec-title">Our Principle of Customer Attraction</h2>
								</div>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5"></div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/badge-check.svg" alt=""></img>
								</div>
								<p className="mb-0">Attracting users to a designated local business, using our LocaNFTs.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/gift.svg" alt=""></img>
								</div>
								<p className="mb-0">Rewarding LocaBonus points upon completing tasks.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/voucher.svg" alt=""></img>
								</div>
								<p className="mb-0">Converting LocaBonus points into D-LocaVoucher.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/arrow-up-and-down.svg" alt=""></img>
								</div>
								<p className="mb-0">Using D-LocaVoucher at affiliated businesses.</p>
							</div>
						</div>
					</div>
				</div>
            </section>
			<div className="page-section for-business-why-section">
				<div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/services/for-businesses/why-choose-us.jpg)' }}></div>
				<div className="container-xl">
					<div className="row">
						<div className="col-xl-7">
							<div className="sec-header">
								<h2 className="sec-title">Why Choose Our Services?</h2>
								<p className="sec-description">We are a technology company led by a team of experts boasting over 20 years of experience. Dive into the realm of cutting-edge marketing solutions with our unique pay-after-results approach, facilitating the acquisition of new customers and a substantial increase in sales revenue. Our extensive and high-quality user community spans the globe, ensuring a global reach and impactful presence. Trust us for unparalleled expertise, innovative solutions, and a truly global network.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
            <section className="page-section mt-5">
				<div className="container-xl">
					<div className="sec-header text-center">
						<h2 className="sec-title">Becoming Our Partner</h2>
						<p className="sec-description">Follow Our Guide to Begin the Endless Journey of<br className="desktop" /> Hunting Items and Gifts</p>
						<a className="btn btn-lg btn-primary btn-icon-right px-5 mt-4" target="_blank" href="https://download.locamos.com">
							Download Now<i className="fa-regular fa-sharp fa-arrow-down-to-line"></i>
						</a>
					</div>
					<div className="sec-content">
						<div className="row justify-content-center">
							<div className="col-md-8">
								<div className="service-step-item">
									<div className="title-with-line">
										01
										<div className="w-100"></div>
									</div>
									<p className="description">
										Create a free account on our website at https://locabiz.net
									</p>
								</div>
								<div className="service-step-item">
									<div className="title-with-line">
										02
										<div className="w-100"></div>
									</div>
									<p className="description">
										Submit necessary registration documents.
									</p>
								</div>
								<div className="service-step-item">
									<div className="title-with-line">
										03
										<div className="w-100"></div>
									</div>
									<p className="description">
										Expect approval within 48 hours.
									</p>
								</div>
								<div className="service-step-item">
									<div className="title-with-line">
										04
										<div className="w-100"></div>
									</div>
									<p className="description">
										Upon approval, login and verify your account status.
									</p>
								</div>
								<div className="service-step-item">
									<div className="title-with-line">
										05
										<div className="w-100"></div>
									</div>
									<p className="description">
										Follow our guidance on utilizing app features for your business.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const ForPartners = connect(mapStateToProps, actionCreators)(props => {
    const { lang, locale_string } = props.pageInfo;

    return (
        <ScreenWithNavBarAndFooter className="services-page">
			<div className="page-bg" style={{ backgroundImage: 'url(/public_assets/images/services/services-bg.svg)' }}></div>
            <section className="page-section page-header services-header">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <button className="btn btn-lg btn-outline-light btn-rounded px-5 mb-5">
                            <i className="fa-regular fa-user-group fa-lg me-3"></i>For Partners
                        </button>
                        <h2 className="sec-title">Unlock Success - Invest in LocaNFTs for the Key to LocaMos Ecosystem Growth</h2>
                        <p className="sec-description">LocaNFTs serve as the main foundation of the LocaMos ecosystem, making them a prime focus for your investment. Don't miss out on the opportunity to be part of the growth and success of LocaNFTs today.</p>
                    </div>
                </div>
            </section>
            <section className="page-section">
				<div className="container-xl">
					<div className="sec-content">
						<div className="what-are-loca-nfts mb-5">
							<div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/services/for-partners/locanfts.png)' }}></div>
							<div className="row">
								<div className="col-md-5">
									<h3>What are LocaNFTs?</h3>
									<p>LocaNFTs (Location Non-Fungible Tokens) are digital representations of specific places stored securely on the Blockchain. These tokens establish a unique and immutable record of location ownership. LocaNFTs generate LocaBonus through the LocaMeet, LocaBiz, and LocaCall algorithms, forming the bedrock of the ecosystem.</p>
									<a href="#" className="btn btn-link btn-icon-right p-0">Own your NFTs<i className="fa-regular fa-sharp fa-arrow-right-long"></i></a>
								</div>
							</div>
						</div>
						<div className="sec-header text-center">
							<h2 className="sec-title">The Challenge in Marketing Today</h2>
							<p className="sec-description">Ever wondered why your online ads aren't bringing in the expected results? Tired of paying upfront without knowing if it's worth it? Small and Medium businesses (SMEs) often struggle with online advertising, unsure if the investment will lead to actual results. The upfront costs of traditional marketing can be intimidating, especially when there's uncertainty about its effectiveness.</p>
						</div>
					</div>
				</div>
            </section>
            <section className="page-section">
				<div className="container-xl">
					<div className="sec-content">
						<div className="row justify-content-around">
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="sec-header">
									<h2 className="sec-title mb-0">The Power of LocaNFTs</h2>
								</div>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<p>LocaNFTs offer opportunities for sustainable asset growth and the creation of diverse passive income:</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/maps.svg" alt=""></img>
								</div>
								<h5 className="h4">Value Appreciation</h5>
								<p className="mb-0">LocaNFTs offer potential growth tied to location popularity, ensuring a valuable investment over time.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5 mb-4 mb-md-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/gift.svg" alt=""></img>
								</div>
								<h5 className="h4">Diverse Passive Income</h5>
								<p className="mb-0">LocaNFT holders enjoy varied streams of passive income, with 20% LocaBonus from mining, 55% from Advertising Revenue, 55-80% from Booth Rental Revenue, and 15% from Items/Voucher Issuance. Additional benefits for upcoming features such as LocaConnect, LocaDating, etc.</p>
							</div>
							<div className="col-md-5 col-xl-5 px-xl-5">
								<div className="glowing-icon">
									<img src="/public_assets/images/element-icons/lock-open.svg" alt=""></img>
								</div>
								<h5 className="h4">Investment Proposition</h5>
								<p className="mb-0">LocaNFTs combine digital assets with real-world value, ensuring long-term growth and diverse income opportunities.</p>
							</div>
						</div>
					</div>
				</div>
            </section>
			<div className="page-section for-partner-cta-section mt-5">
				<div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/services/for-partners/cta.jpg)' }}></div>
				<div className="container-xl">
					<div className="row">
						<div className="col-xl-7">
							<div className="sec-header">
								<h2 className="sec-title">Join the Future</h2>
								<p className="sec-description mb-4">Invest in LocaNFTs for an innovative fusion of digital assets and real-world significance.</p>
								<a href="#" className="btn btn-lg btn-outline-light btn-icon-right">Own your NFTs<i className="fa-regular fa-credit-card-blank"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    ForUsers,
	ForBusinesses,
	ForPartners
};