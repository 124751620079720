import React from 'react';
import 'assets/scss/App.scss';

import { BrowserRouter } from 'react-router-dom';
import AppNavigator from 'app/navigations/AppNavigator';

import { AppAlert } from 'app/components';

function App(props) {
	return (
		<div className="site">
			<BrowserRouter>
				<AppNavigator />
			</BrowserRouter>
			<AppAlert />
		</div>
	);
}

export default App;
