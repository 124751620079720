import { orderConstants } from 'app/redux/constants';

let update = formData => {
    return dispatch => {
        dispatch({ type: orderConstants.UPDATE, formData });

        return Promise.resolve();
    }
    // return { type: orderConstants.UPDATE, formData };
}

let clear = () => {
    return { type: orderConstants.CLEAR };
}

export const orderActions = {
    update,
    clear
}