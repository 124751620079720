import React, { useState, useEffect } from 'react';
import './Auth.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { authActions, alertActions, pageInfoActions, orderActions } from 'app/redux/actions';

import { NAVIGATION, GOOGLE_CLIENT_ID, APPLE_CLIENT_ID, APPLE_REDIRECT_URI } from 'config';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import { getParam } from "utils/AppHelper";
import { authService, membershipService } from "services";

import { province_list } from "../Dashboard/data";

import { GoogleOAuthProvider, useGoogleLogin, googleLogout } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';


const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order };
}

const actionCreators = {
    login: authActions.login,
    logout: authActions.logout,
    userUpdate: authActions.user_update,
    userUpdateToken: authActions.user_update_token,
    alertError: alertActions.error,
    alertWarning: alertActions.warning,
    changeLang: pageInfoActions.change_lang,
    clearOrder: orderActions.clear,
    updateOrder: orderActions.update
}

const Login = connect(mapStateToProps, actionCreators)(props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pageInfo, auth, order, login, alertError, alertWarning, userUpdateToken, userUpdate, updateOrder } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error, user } = auth;

    const [loginForm, setLoginForm] = useState({
        username: '',
        password: '',
    })

    useEffect(() => {
        if (isLogin) {
            updateOrder({
                ...order,
                full_name: user.userApp.full_name,
                phone: user.userApp.phone
            }).then(() => {
                if ( location.state && location.state.from ) {
                    navigate(location.state.from, { replace: true });
                    
                } else {
                    navigate(NAVIGATION.MEMBERSHIP, { replace: true, state: { page_title: 'Tem Rau' } });
                }
            })
        }
        if (error) {
            alertError(error);
        }
    }, [isLogin, location, navigate, error, alertError])

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setLoginForm(form_data => ({
            ...form_data,
            [input_name]: value
        }))
    }

    const handleSubmit = e => {
        if (e.keyCode === 13) {
            doLogin();
        } else {
            return null;
        }
    }

    const convertUtf8ToBase64 = (data) => {
        const _unicode = data
            .split('')
            .map((item) => item.charCodeAt(0).toString(16))
            .join('|');
        const _base64 = btoa(_unicode);
        return _base64;
    };

    const doLogin = () => {
        // alertError('Vui lòng truy cập LocaMart trên ứng dụng LocaMos để tiếp tục!');
        // return;

        if (
            loginForm.username.trim().length === 0
            || loginForm.password.trim().length === 0
        ) {
            alertError(text.auth.login.field_can_not_be_blank[lang]);
        } else {
            let user = loginForm.username.toString();

            if (user.indexOf('@') !== -1) {
                // nothing to do with email
            } else {
                let fixed_mobile = '';
                if (user[0] === '0') {
                    fixed_mobile = '+84' + parseInt(user.replace(/[^A-Z0-9]+/ig, '')).toString();;
                } else {
                    fixed_mobile = user.replace(/[^A-Z0-9]+/ig, '');
                }
                // console.log(fixed_mobile);
    
                user = fixed_mobile;
            }

            login(user, convertUtf8ToBase64(loginForm.password));            
        }
    }

    function handleSocialLogin(social_token, social_type) {
        authService.login_social(social_token, social_type)
            .then(data => {
                if (typeof data === 'object') {
                    userUpdateToken(data.access_token);
                    userUpdate(data.access_token);
                } else {
                    let error_text = '';
                    switch (data) {
                        case 'USER_NOT_FOUND':
                            error_text = 'Tài khoản không tồn tại!';
                            break;
                        
                        case 'USER_IS_DELETED':
                            error_text = 'Tài khoản đã bị xóa!';
                            break;
                        
                        case 'USER_IS_BLOCKED':
                            error_text = 'Tài khoản bị khóa! Vui lòng liên hệ CSKH để biết thêm chi tiết';
                            break;
                        
                        case 'USER_IS_LOCKED_TO_TRANSFER':
                            error_text = 'Tài khoản đang bị khóa chuyển điểm!';
                            break;
                        
                        case 'USER_NOT_VALID':
                            error_text = 'Tài khoản đang bị khóa Checkpoint! Vui lòng đăng nhập trên Ứng dụng LocaMos để tiếp tục';
                            break;
                        
                        case 'USER_NEED_KYC_TO_LOGIN':
                            error_text = 'Đăng nhập thất bại. Vui lòng thử lại sau!';
                            break;

                        default:
                            error_text = 'Đăng nhập thất bại. Vui lòng thử lại sau!'
                            break;
                    }
                    
                    alertError(error_text);
                }
            })
    }

    const BtnGoogleLogin = props => {
        const doLoginSocial = useGoogleLogin({
            onSuccess: tokenResponse => {
                // console.log(tokenResponse)
                handleSocialLogin(tokenResponse.access_token, 'GOOGLE');
            },
            onError: errorResponse => {
                console.log(errorResponse);
                alertError('Đăng nhập thất bại. Vui lòng thử lại sau!')
            },
        });

        return (
            <button className="btn btn-outline-light btn-social-login btn-login-google w-100" onClick={doLoginSocial}>
                <img src="/public_assets/images/social-icons/google.png"></img>
                <span>Đăng nhập với Google</span>
            </button>
        )
    }

    function handleOpenApp(e) {
        console.log(e);
        e.preventDefault();

        alertWarning('Hãy tải ứng dụng LocaMos để đăng ký tài khoản!');
        try {
            window.location.href = 'https://locamosapp.page.link/?link=https://locamos.vn&apn=com.locamosapp&isi=6443998284&ibi=com.locamosapp';
    
            // setTimeout(function () {
            //     window.location.href = 'https://download.locamos.com';
            // }, 500)
        } catch (error) {
            window.location.href = 'https://download.locamos.com';
            console.log(error);
        }
    }

    return (
        <div className="page-auth bg-primary bg-gradient">
            <div className="container">
                <Link to={NAVIGATION.DASHBOARD} className="auth-logo">
                    <img src="/public_assets/images/header-logo.svg" alt="" height={64}></img>
                </Link>
                <div className="card auth-form mx-auto">
                    <h1 className="card-header">{text.auth.login[lang]}</h1>
                    <div className="card-body">
                        <div className="mb-4">
                            <div className="mb-3">
                                <GoogleOAuthProvider 
                                    clientId={GOOGLE_CLIENT_ID}
                                >
                                    <BtnGoogleLogin />
                                </GoogleOAuthProvider>
                            </div>
                            <div className="mb-3">
                                <AppleLogin
                                    designProp={{
                                        height: 40,
                                        width: 100,
                                    }}
                                    clientId={APPLE_CLIENT_ID}
                                    redirectURI={APPLE_REDIRECT_URI}
                                    usePopup={true}
                                    responseMode={"query"}
                                    callback={(response) => {
                                        if (response.authorization) {
                                            const { id_token } = response.authorization;
                                            handleSocialLogin(id_token, 'APPLE');
                                        } else {
                                            alertError('Đăng nhập thất bại. Vui lòng thử lại sau!')
                                        }
                                    }}
                                    render={(e) => {
                                        return (
                                            <button
                                                className="btn btn-dark btn-social-login w-100"
                                                onClick={() => {
                                                    e.onClick();
                                                }}
                                            >
                                                <img src="/public_assets/images/social-icons/apple.png"></img>
                                                <span>Đăng nhập với Apple</span>
                                            </button>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">{text.auth.login.username[lang]}</label>
                            <input type="text" className="form-control" id="username" name="username" onChange={e => {handleFormChange(e, 'username')}} onKeyUp={handleSubmit}></input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">{text.auth.login.password[lang]}</label>
                            <input type="password" className="form-control" id="password" name="password" onChange={e => {handleFormChange(e, 'password')}} onKeyUp={handleSubmit}></input>
                        </div>
                        <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center mt-4 mb-1">
                            {/* <a href="https://find.locamos.vn/forgot-password" target="_blank" className="btn btn-link">{text.auth.login.forgot_password[lang]}</a> */}
                            <Link to={NAVIGATION.FORGOT_PASSWORD} className="btn btn-link">{text.auth.login.forgot_password[lang]}</Link>
                            <button type="submit" className="btn btn-primary px-5" onClick={doLogin} disabled={apiRequest}>{text.auth.login.login[lang]}</button>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="d-flex flex-row flex-nowrap justify-content-center">
                            <a href="" className="btn btn-link" onClick={handleOpenApp}>{text.auth.login.register[lang]}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

const Register = connect(mapStateToProps, actionCreators)(props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pageInfo, auth, order, alertError } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error } = auth;

    const ref_id = getParam('id');

    const [registerForm, setRegisterForm] = useState({
        username: '',
        full_name: order.full_name || '',
        province_id: order.province_id || '',
        province_text: order.province_text || '',
        district_id: order.district_id || '',
        district_text: order.district_text || '',
        address: order.address || '',
        ref_id: ''
    })

    const [districtList, setDistrictList] = useState(() => {
        if (registerForm.province_id !== '' && registerForm.district_id !== '') {
            let district_list = province_list.filter(item => item.Id === registerForm.province_id);
            if (district_list.length > 0) {
                return district_list[0].Districts;
            } else {
                return [];
            }
        } else {
            return [];
        }
    });

    const [referer, setReferer] = useState(null);

    const [loading, setLoading] = useState(false);
    const [errorHighlight, setErrorHighlight] = useState([]);

    // useEffect(() => {
    //     console.log('????? ref_id', ref_id);

    //     if (ref_id !== '') {
    //         authService.fetch_referer_info(ref_id)
    //             .then(res => {
    //                 console.log('?????? ref_info', res);
    //                 if (typeof res === 'object') {
    //                     setReferer(res);
    //                     setRegisterForm(form_data => ({
    //                         ...form_data,
    //                         ['ref_id']: ref_id
    //                     }))
    //                 } else {
    //                     setReferer(null)
    //                 }
    //             })
    //     }

    //     return () => {
    //     }
    // }, [ref_id, setReferer])
    

    useEffect(() => {
        if (isLogin) {
            if ( location.state && location.state.from ) {
                navigate(location.state.from, { replace: true });
            } else {
                navigate(NAVIGATION.DASHBOARD, { replace: true });
            }
        }
        if (error) {
            alertError(error);
        }
    }, [isLogin, location, navigate, error, alertError])

    function renderProvinceOptions(list) {
        if (!list || list.length === 0) return null;

        let provinces = [];
        list.forEach((item, index) => {
            provinces.push(
                <option value={item.Id} key={'province_option_' + index}>{item.Name}</option>
            )
        })

        return provinces;
    }

    function handleProvinceSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        if (!id || id === '') {
            setDistrictList([]);
            return null;
        };

        setRegisterForm(form => {
            return {
                ...form,
                district_id: '',
                district_text: '',
                province_id: id,
                province_text: text
            }
        });

        // console.log(id, index, text);

        let district_list = province_list.filter(item => item.Id === id);
        if (district_list.length > 0) {
            setDistrictList(district_list[0].Districts);   
        }
    }

    function renderDistrictOptions(list) {
        if (!list || list.length === 0) return null;

        let districts = [];
        list.forEach((item, index) => {
            districts.push(
                <option value={item.Id} key={'district_option_' + index}>{item.Name}</option>
            )
        })

        return districts;
    }

    function handleDistrictSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        setRegisterForm(form => {
            return {
                ...form,
                district_id: id,
                district_text: text
            }
        });
    }

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setRegisterForm(form_data => ({
            ...form_data,
            [input_name]: value
        }))
    }

    function validateForm() {
        alertError('Vui lòng truy cập LocaMart trên ứng dụng LocaMos để tiếp tục!');
        return;

        const form_field = [
            {
                field_id: 'username',
                field_name: 'SĐT/Email đăng nhập'
            },
            {
                field_id: 'password',
                field_name: 'Mật khẩu'
            },
            {
                field_id: 'full_name',
                field_name: 'Họ và tên'
            },
            {
                field_id: 'province_id',
                field_name: 'Tỉnh/Thành phố'
            },
            {
                field_id: 'district_id',
                field_name: 'Quận/Huyện'
            },
            {
                field_id: 'address',
                field_name: 'Địa chỉ nhận hàng'
            },
            {
                field_id: 'ref_id',
                field_name: 'Mã giới thiệu'
            }
        ]

        let error_field = [];
        form_field.forEach(item => {
            if (!registerForm[item.field_id] || registerForm[item.field_id] === '') {
                error_field.push(item.field_id);
            }
        })

        if (error_field.length > 0) {
            let text = '';

            if (error_field.length < 3) {
                error_field.forEach(item => {
                    try {
                        let field = form_field.filter(field => field.field_id === item)[0];
                        text += ', ' + field.field_name;                        
                    } catch (error) {
                        //
                    }
                });
            } else {
                text = '  Các trường thông tin';
            }
            setErrorHighlight(error_field);
            return text;

        } else {
            return '';
        }
    }

    const doRegister = (e) => {
        e.preventDefault();

        alertError('Vui lòng truy cập LocaMart trên ứng dụng LocaMos để tiếp tục!');
        return;

        setLoading(true);

        let validate = validateForm();
        if (validate !== '') {
            setLoading(false);
            alertError(validate.slice(2) + ' không được để trống!');
        } else {
            let user = registerForm.username.toString();

            if (user.indexOf('@') !== -1) {
                // nothing to do with email
            } else {
                let fixed_mobile = '';
                if (user[0] === '0') {
                    fixed_mobile = '84' + parseInt(user.replace(/[^A-Z0-9]+/ig, '')).toString();;
                } else {
                    fixed_mobile = user.replace(/[^A-Z0-9]+/ig, '');
                }
                // console.log(fixed_mobile);
    
                user = fixed_mobile;
            }
            
            authService.register({
                ...registerForm,
                username: user
            })
                .then(data => {
                    setLoading(false);
                    console.log('Register', data);
                })
        }
    }

    function errorHighlightCheck(field) {
        return errorHighlight.indexOf(field) !== -1 ? 'is-invalid' : '';
    }

    return (
        <div className="page-auth bg-primary bg-gradient">
            <div className="container">
                <Link to={NAVIGATION.DASHBOARD} className="auth-logo">
                    <img src="/public_assets/images/header-logo.svg" alt="" height={64}></img>
                </Link>
                <div className="card auth-form register-form mx-auto">
                    <h5 className="card-header">{text.auth.register[lang]}</h5>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 pe-md-2">
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">{text.auth.register.username[lang]} <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${ errorHighlightCheck('username') }`} id="username" defaultValue={registerForm.username} onChange={e => handleFormChange(e, 'username')}></input>
                                </div>
                            </div>
                            <div className="col-md-6 ps-md-2">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">{text.auth.register.password[lang]} <span className="text-danger">*</span></label>
                                    <input type="password" className={`form-control ${ errorHighlightCheck('password') }`} id="password" onChange={e => handleFormChange(e, 'password')}></input>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="full_name" className="form-label">{text.auth.register.full_name[lang]} <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${ errorHighlightCheck('full_name') }`} id="full_name" defaultValue={registerForm.full_name} onChange={e => handleFormChange(e, 'full_name')}></input>
                                </div>
                            </div>
                            <div className="col-md-6 pe-md-2">
                                <div className="mb-3">
                                    <label htmlFor="province_id" className="form-label">{text.auth.register.province[lang]}</label>
                                    <select className={`form-select ${ errorHighlightCheck('province_id') }`} id="province_id" aria-label="Tỉnh/Thành phố" defaultValue={registerForm.province_id} onChange={handleProvinceSelect}>
                                        <option value="">{text.auth.register.please_select[lang]}</option>
                                        {renderProvinceOptions(province_list)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 ps-md-2">
                                <div className="mb-3">
                                    <label htmlFor="district_id" className="form-label">{text.auth.register.district[lang]}</label>
                                    <select className={`form-select ${ errorHighlightCheck('district_id') }`} id="district_id" aria-label="Quận/Huyện" value={registerForm.district_id} onChange={handleDistrictSelect}>
                                        <option value="">{text.auth.register.please_select[lang]}</option>
                                        {renderDistrictOptions(districtList)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="address" className="form-label">{text.auth.register.address[lang]}</label>
                                    <input type="text" className={`form-control ${ errorHighlightCheck('address') }`} id="address" defaultValue={registerForm.address} onChange={e => handleFormChange(e, 'address')}></input>
                                </div>
                            </div>
                            {
                                !referer
                                ? <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="ref_id" className="form-label">{text.auth.register.code_ref[lang]}</label>
                                        <input type="tel" className={`form-control ${ errorHighlightCheck('ref_id') }`} name="ref_id" id="ref_id" onChange={e => handleFormChange(e, 'ref_id')}></input>
                                    </div>
                                </div>
                                : <div className="col-12">
                                    <p>Người giới thiệu: <b>{referer.full_name}</b></p>
                                </div>
                            }
                            <div className="col-12">
                                <div className="mb-3">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input me-2" id="customCheck1" checked disabled></input>
                                        <label className="custom-control-label mb-0" htmlFor="customCheck1">Bằng việc đăng ký tài khoản, bạn đồng ý với <Link to={NAVIGATION.TERMS_OF_SERVICE}>Điều khoản dịch vụ</Link> và <Link to={NAVIGATION.PRIVACY_POLICY}>Chính sách bảo mật</Link> của chúng tôi.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn d-block w-100 btn-primary mt-2 mb-1" onClick={doRegister} disabled={loading}>{text.auth.register.register[lang]}</button>
                    </div>
                    <div className="card-footer">
                        <div className="d-flex flex-row flex-nowrap justify-content-center">
                            <Link to={NAVIGATION.LOGIN} className="btn btn-link p-0">{text.auth.return_to_login[lang]}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

const ForgotPassword = connect(mapStateToProps, actionCreators)(props => {
    // console.log('ForgotPassword ', props);
    const location = useLocation();
    const navigate = useNavigate();
    const { pageInfo, auth, alertError } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error } = auth;

    const [loginForm, setLoginForm] = useState({
        username: '',
    })

    useEffect(() => {
        if (isLogin) {
            if ( location.state && location.state.from ) {
                navigate(location.state.from, { replace: true });
            } else {
                navigate(NAVIGATION.DASHBOARD, { replace: true });
            }
        }
        if (error) {
            alertError(error);
        }
    }, [isLogin, location, navigate, error, alertError])

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setLoginForm(form_data => ({
            ...form_data,
            [input_name]: value
        }))
    }

    const handleSubmit = e => {
        if (e.keyCode === 13) {
            doForgetPassword();
        } else {
            return null;
        }
    }

    const doForgetPassword = () => {
        alertError('Vui lòng truy cập LocaMart trên ứng dụng LocaMos để tiếp tục!');
        return;
        
        if (
            loginForm.username.trim().length === 0
        ) {
            alertError(text.auth.forgot_password.field_can_not_be_blank[lang]);
        } else {
            console.log('Forget Password', loginForm);
        }
    }

    return (
        <div className="page-auth bg-primary bg-gradient">
            <div className="container">
                <Link to={NAVIGATION.DASHBOARD} className="auth-logo">
                    <img src="/public_assets/images/header-logo.svg" alt="" height={64}></img>
                </Link>
                <div className="card auth-form mx-auto">
                    <h5 className="card-header">{text.auth.forgot_password[lang]}</h5>
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">{text.auth.login.username[lang]}</label>
                            <input type="text" className="form-control" id="username" name="username" onChange={e => {handleFormChange(e, 'username')}} onKeyUp={handleSubmit}></input>
                        </div>
                        <button type="submit" className="btn d-block w-100 btn-primary mt-4 mb-1" onClick={doForgetPassword} disabled={apiRequest}>{text.auth.forgot_password.send_request[lang]}</button>
                    </div>
                    <div className="card-footer">
                        <div className="d-flex flex-row flex-nowrap justify-content-center">
                            <Link to={NAVIGATION.LOGIN} className="btn btn-link">{text.auth.return_to_login[lang]}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

const Logout = connect(mapStateToProps, actionCreators)(props => {
    const navigate = useNavigate();
    const { logout, clearOrder } = props;

    useEffect(() => {
        clearOrder();
        logout();
        try {
            googleLogout();
        } catch (error) {
            console.log(error);
        }
        navigate(NAVIGATION.LOGIN, { replace: true });
    }, [logout, navigate]);

    return null;
})

const AuthToken = connect(mapStateToProps, actionCreators)(props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pageInfo, auth, login, alertError, userUpdate } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error, user } = auth;

    useEffect(() => {
        try {
            if (user.token && user.token !== '') {
                userUpdate(user.token);
            }
        } catch (error) {
            console.log(error);
        }
        
        return () => {
        }
    }, [user])
    

    useEffect(() => {
        if (isLogin) {
            if ( location.state && location.state.from ) {
                navigate(location.state.from, { replace: true });
            } else {
                navigate(NAVIGATION.MEMBERSHIP, { replace: true, state: { page_title: 'Tem Rau' } });
            }
        }
        if (error) {
            alertError(error);
        }
    }, [isLogin, location, navigate, error, alertError])

    return (
        <div className="page-auth bg-primary bg-gradient">
            <div className="container">
                <Link to={NAVIGATION.DASHBOARD} className="auth-logo">
                    <img src="/public_assets/images/header-logo.svg" alt="" height={64}></img>
                </Link>
                <div className="card auth-form mx-auto">
                    <div className="card-body">
                        {
                            apiRequest
                            ? <p>Đang tải dữ liệu người dùng. Vui lòng chờ trong giây lát...</p>
                            : isLogin
                                ? <p>Chào mừng bạn tới LocaMart</p>
                                : <p>Thông tin đăng nhập không hợp lệ. Vui lòng đăng nhập lại ứng dụng LocaMos để tiếp tục!</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

export {
    Login,
    AuthToken,
    Register,
    ForgotPassword,
    Logout
}