import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { alert } from './alert.reducer';
import { auth } from './auth.reducer';
import { pageInfo } from './page_info.reducer';
import { order } from "./order.reducer";

const authConfig = {
  key: 'auth',
  storage,
  blacklist: ['isLoading', 'apiRequest', 'error']
}

const rootReducer = combineReducers({
  alert,
  auth: persistReducer(authConfig, auth),
  pageInfo,
  order
});

export default rootReducer;