import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { alertActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const {  } = state;
    return {  }
}

const actionCreators = {
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}

const InputWithCopyButton = connect(mapStateToProps, actionCreators)(({
    id,
    label,
    placeholder,
    className,
    btnClassName,
    value,
    onChange,
    alertSuccess,
    alertError
}) => {
    function copyText(e) {
        let input = document.getElementById(id);
        input.select();

        // if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        //     let el = input;
        //     let editable = el.contentEditable;
        //     let readOnly = el.readOnly;
        //     el.contentEditable = true;
        //     el.readOnly = true;
        //     let range = document.createRange();
        //     range.selectNodeContents(el);
        //     let sel = window.getSelection();
        //     sel.removeAllRanges();
        //     sel.addRange(range);
        //     el.setSelectionRange(0, 999999);
        //     el.contentEditable = editable;
        //     el.readOnly = readOnly;
        // } else {
        //     input.select();
        // }

        try {
            document.execCommand("copy");  // Security exception may be thrown by some browsers.
            alertSuccess('Đã sao chép ' + label);
        } catch (error) {
            console.warn("Copy to clipboard failed.", error);
            return false;
        }

        input.blur();
    }
    return (
        <div className={`form-input-with-copy ${className ? className : ''}`}>
            <label htmlFor={id} className="form-label">{label}</label>
            <div className="input-group">
                <input type="text" className="form-control" id={id} placeholder={placeholder ? placeholder : label} value={value} onChange={onChange} readOnly></input>
                <button className={`btn btn-icon ${btnClassName ? btnClassName : 'btn-outline-secondary'}`} type="button" onClick={copyText}><i className="fa-light fa-sharp fa-copy"></i></button>
            </div>
        </div>
    )
})

export {
    InputWithCopyButton
}