import React from 'react';
import './Contact.scss';

import { connect } from 'react-redux';

import { ScreenWithNavBarAndFooter } from 'app/components';

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const Contact = connect(mapStateToProps, actionCreators)(props => {
    const { lang, locale_string } = props.pageInfo;
    
    return (
        <ScreenWithNavBarAndFooter className="page-contact-us">
            <section className="page-section page-header">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/contact-us/hero.jpg)' }}>
                </div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h1 className="sec-title">Contact Us</h1>
                        <p className="sec-description">
                            Join us to discover the LocaMos MarTech today!
                        </p>
                        <div className="d-flex flex-row flex-wrap justify-content-center gap-3 gap-lg-5 mt-3 fw-bold">
                            <a href="https://www.facebook.com/LocaMosGlobalAg" target="_blank"><i className="fa-brands fa-facebook me-1"></i>LocaMosGlobalAg</a>
                            <a href="https://twitter.com/@LocaMosGlobalAg" target="_blank"><i className="fa-brands fa-x-twitter me-1"></i>@LocaMosGlobalAg</a>
                            <a href="https://www.linkedin.com/company/joinlocamos/" target="_blank"><i className="fa-brands fa-linkedin me-1"></i>joinlocamos</a>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="page-section contact-info-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="contact-info-item">
                                <div className="glowing-icon mb-2">
                                    <img src="/public_assets/images/element-icons/city.svg" alt="" />
                                </div>
                                <h3>327 Spring Haven Trail</h3>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="contact-info-item">
                                <div className="glowing-icon mb-2">
                                    <img src="/public_assets/images/element-icons/envelope-open.svg" alt="" />
                                </div>
                                <h3>info@locamos.com</h3>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="contact-info-item">
                                <div className="glowing-icon mb-2">
                                    <img src="/public_assets/images/element-icons/call.svg" alt="" />
                                </div>
                                <h3>123 456 789</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="page-section send-message-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 desktop">
                            <div className="section-bg rounded-5" style={{ backgroundImage: 'url(/public_assets/images/contact-us/contact-form.jpg)' }}></div>
                            <div className="h-100 d-flex flex-column justify-content-end p-4">
                                {/* <h2 className="h1 fw-bold">Leave your<br/>Message</h2> */}
                            </div>
                        </div>
                        <div className="col-md-7 ps-md-5 py-md-5">
                            <div className="sec-header">
                                <h2 className="sec-title">Send A Message</h2>
                            </div>
                            <div className="sec-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-group mb-3 mb-md-4">
                                            <input type="text" className="form-control form-control-lg" placeholder="Your Name" />
                                            <span className="input-group-text">
                                                <i className="fa-regular fa-fw fa-lg fa-user"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group mb-3 mb-md-4">
                                            <input type="text" className="form-control form-control-lg" placeholder="Your Email" />
                                            <span className="input-group-text">
                                                <i className="fa-regular fa-fw fa-lg fa-envelope-open"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group mb-3 mb-md-5">
                                            <textarea rows={8} className="form-control form-control-lg" placeholder="Your Message"></textarea>
                                            <span className="input-group-text">
                                                <i className="fa-regular fa-fw fa-lg fa-edit"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-lg btn-primary px-5">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    Contact
};