import { authConstants } from 'app/redux/constants';

const initialState = {
    apiRequest: false,
	isLogin: false,
    user: {
        token: null,
        auth_method: null,
    },
    isLoading: false,
    error: null
}

export function auth(state = initialState, action) {
	switch (action.type) {
		case authConstants.LOGIN_REQUEST:
            return {
                apiRequest: true,
                isLogin: false,
                user: action.user,
                isLoading: true,
                error: null
            }
        case authConstants.LOGIN_SUCCESS:
            return {
                apiRequest: false,
                isLogin: true,
                user: action.user,
                isLoading: false,
                error: null
            }
        case authConstants.LOGIN_FAILURE:
            return {
                ...initialState,
                user: {
                    token: null,
                    auth_method: null
                },
                error: action.error
            };

        case authConstants.LOGOUT:
            return initialState;
            
        case authConstants.USERS_UPDATE_INFO:
            return {
                apiRequest: false,
                isLogin: true,
                user: {
                    ...action.user,
                    auth_method: state.user.auth_method
                },
                isLoading: false,
                error: null
            }
        
        case authConstants.USERS_UPDATE_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.auth
                }
            };
		default:
			return state
	}
}