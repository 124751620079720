import { authConstants } from 'app/redux/constants';
import { authService } from 'services';

function request(user) { return { type: authConstants.LOGIN_REQUEST, user } }
function success(user) { return { type: authConstants.LOGIN_SUCCESS, user } }
function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }

const login = (username, password) => {
    return dispatch => {
        dispatch(request({ username, auth_method: 'FORM' }));

        authService.login(username, password)
            .then(res => {
                // console.log('?????? auth action', res);
                if (typeof res === 'object') {
                    authService.fetch_user_info(res.access_token)
                        .then(data => {
                            if (typeof data === 'object') {
                                let user_info = {
                                    ...data,
                                    token: res.access_token,
                                    auth_method: 'FORM'
                                }
                                dispatch(success(user_info));
                            } else {
                                dispatch(failure(data))
                            }
                        })
                } else {
                    dispatch(failure(res))
                }
            })
    }
}

const auth_token = (token) => {
    return dispatch => {
        dispatch(request({ token, auth_method: 'TOKEN' }));

        authService.fetch_user_info(token)
            .then(res => {
                if (typeof res === 'object') {
                    let user_info = {
                        ...res,
                        token,
                        auth_method: 'TOKEN'
                    }
                    dispatch(success(user_info));
                } else {
                    dispatch(failure(res));
                }
            })
    }
}

const logout = () => {
    return dispatch => {
        dispatch({ type: authConstants.LOGOUT });
    }
}

const user_update = (token) => {
    return dispatch => {
        authService.fetch_user_info(token)
            .then(res => {
                if (typeof res === 'object') {
                    let user_info = {
                        ...res,
                        token
                    }
                    dispatch({ type: authConstants.USERS_UPDATE_INFO, user: user_info });
                } else {
                    dispatch(failure(res))
                }
            })
    }
}

const user_update_token = (token) => {
    return dispatch => {
        dispatch({ type: authConstants.USERS_UPDATE_TOKEN, auth: { auth_method: 'TOKEN', token } });
    }
}

export const authActions = {
    login,
    auth_token,
    logout,
    user_update,
    user_update_token
}