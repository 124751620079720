import { alertConstants } from 'app/redux/constants';

let success = message => {
    return { type: alertConstants.SUCCESS, message };
}

let info = message => {
    return { type: alertConstants.INFO, message };
}

let warning = message => {
    return { type: alertConstants.WARNING, message };
}

let error = message => {
    return { type: alertConstants.ERROR, message };
}

let clear = id => {
    return { type: alertConstants.CLEAR, id };
}

export const alertActions = {
    success,
    info,
    warning,
    error,
    clear
}